var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('item-list-view',{attrs:{"api":_vm.api,"headers":_vm.headers,"id-key":"id","title":"Налоговые вычеты","search-placeholder":"Поиск по номеру справки, фио/id пациента","allowed-search":true,"allowed-create":false,"allowed-remove":false,"view-route":"TaxDeduction","request-params":_vm.filters},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('legal-entity-name-select',{staticClass:"ml-4",staticStyle:{"width":"240px","flex":"0 0 auto"},attrs:{"hide-details":"","single-line":"","label":"Legal entity"},model:{value:(_vm.filters.legalEntityName),callback:function ($$v) {_vm.$set(_vm.filters, "legalEntityName", $$v)},expression:"filters.legalEntityName"}})]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm._f("dateTime")(item.createdAt,'DD.MM.YYYY'))+" ")]),_c('div',{staticStyle:{"opacity":"0.55"}},[_vm._v(" в "+_vm._s(_vm._f("dateTime")(item.createdAt,'HH:mm'))+" ")])])]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.period.start))+" - "+_vm._s(_vm._f("date")(item.period.end))+" ")]}},{key:"item.payer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fullName")(item.payer))+" ")]}},{key:"item.patient",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fullName")(item.patient))+" ")]}},{key:"item.certificatesOfNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.certificates.map(function (c) { return c.id; }).join(', '))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status',{attrs:{"value":item.status}})]}},{key:"item.legalEntities",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.legalEntities)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-select
    v-bind="{
      ...$attrs,
      loading,
      items,
      itemTitle: 'title',
      itemValue: 'value'
    }"
    v-on="$listeners"
  >
    <template #selection="{ item }">
      {{ item.title }}
    </template>
    <template #item="{ item }">{{ item.title }}</template>
  </v-select>
</template>

<script>
import service from '../../services/tax-deduction'

export default {
  data () {
    return {
      loading: true,
      items: [],
    }
  },
  async created () {
    let error
    this.loading = true
    try {
      const items = await service.getLegalEntityNames()
      this.items = items.map((title) => ({
        title: title || 'Все',
        value: title || ''
      }))
    } catch (e) {
      error = e
    }
    this.loading = false
    if (error) throw error
  }
}
</script>

<!-- <template>
  <AutocompleteAsync
    v-on="$listeners"
    v-bind="attrs"
  />
</template>

<script>
import AutocompleteAsync from '../Cart/AutocompleteAsync.vue'

export default {
  components: {
    AutocompleteAsync
  },
  props: {
    type: String,
    validator: (prop) => Object.keys(types).includes(prop),
    required: true,
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        itemText: 'name',
        itemValue: 'id',
        load: (...args) => this.load(...args)
      }
    }
  },
  methods: {
    async load (params) {
      const api = services[this.type]
      const { items } = await api.getAll(params)
      return items
    }
  }
}
</script> -->

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.title))]}}])},'v-select',Object.assign({}, _vm.$attrs,
    {loading: _vm.loading,
    items: _vm.items,
    itemTitle: 'title',
    itemValue: 'value'}),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <item-list-view
    :api="api"
    :headers="headers"
    id-key="id"
    title="Налоговые вычеты"
    search-placeholder="Поиск по номеру справки, фио/id пациента"
    :allowed-search="true"
    :allowed-create="false"
    :allowed-remove="false"
    view-route="TaxDeduction"
    :request-params="filters"
  >
    <template v-slot:filters>
      <legal-entity-name-select
        class="ml-4"
        style="width: 240px; flex: 0 0 auto"
        hide-details
        single-line
        label="Legal entity"
        v-model="filters.legalEntityName"
      ></legal-entity-name-select>
    </template>
    <template v-slot:item.createdAt="{ item }">
      <div>
        <div>
          {{ item.createdAt | dateTime('DD.MM.YYYY') }}
        </div>
        <div style="opacity: 0.55">
          в {{ item.createdAt | dateTime('HH:mm') }}
        </div>
      </div>
      <!-- <span style="font-size: 0.85em">
        {{ item.period.start | date }} — {{ item.period.end | date }}
      </span> -->
    </template>
    <template v-slot:item.period="{ item }">
      {{ item.period.start | date }} - {{ item.period.end | date }}
    </template>
    <template v-slot:item.payer="{ item }">
      {{ item.payer | fullName }}
    </template>
    <template v-slot:item.patient="{ item }">
      {{ item.patient | fullName }}
    </template>
    <template v-slot:item.certificatesOfNumber="{ item }">
      {{ item.certificates.map(c => c.id).join(', ') }}
    </template>
    <template v-slot:item.status="{ item }">
      <status :value="item.status"></status>
    </template>
    <template v-slot:item.legalEntities="{ item }">
      {{ item.legalEntities }}
    </template>
  </item-list-view>
</template>

<script>
import api from '../../services/tax-deduction'
import ItemListView from '../../components/ItemListView'
import Status from './Status'
import LegalEntityNameSelect from '../../components/LegalEntity/LegalEntityNameSelect.vue'

export default {
  components: {
    ItemListView,
    Status,
    LegalEntityNameSelect,
  },
  data: () => ({
    api: api,
    filters: {
      legalEntityName: null
    },
    headers: [
      { text: 'Создано', value: 'createdAt' },
      { text: 'Период', value: 'period' },
      { text: 'Плательщик', value: 'payer' },
      { text: 'Пациент', value: 'patient' },
      { text: '№ справок', value: 'certificatesOfNumber' },
      { text: 'Статус', value: 'status' },
      { text: 'Legal entities', value: 'legalEntities' },
      { text: '', value: 'action', align: 'end' }
    ]
  }),
}
</script>
